import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout/layout"
import Seo from "../components/seo/seo"
import Divider from "../components/divider/divider"

const description =
  "We are iPhupha Digital. A transformative digital technologies company. On a mission to become essential for influential small businesses and brands by delivering transformative cloud solutions, services, and resources to help them be digital, future-proof, and customer-centric."

const AboutPage = () => {
  const transformOptions = { grayscale: true }

  return (
    <Layout>
      <Seo title="About" description={description} />
      <section className="container mt-5">
        <div className="mw-800">
          <h4>
            We are iPhupha Digital. A transformative digital technologies
            company.
          </h4>
          <p className="body1">
            On a mission to become essential for influential small businesses
            and brands by delivering transformative cloud solutions, services,
            and resources to help them be digital, future-proof, and
            customer-centric.
          </p>
        </div>
        <Divider />
        <StaticImage
          className="mt-5"
          src="../images/about-digital-creator-graphic-designer-editor-on-photoshop-thanzi-thanzeer.jpg"
          alt="A digital creator using their laptop to edit a picture on Photoshop."
          transformOptions={transformOptions}
        />
        <div className="row mt-5 mb-5 justify-space-between">
          <h4 className="col-md-5 mw-800">
            Our culture aims to reflect our values through an open and
            collaborative space.
          </h4>
          <div className="row col-md-6">
            <div className="col-xs-12 col-md-6 pr-2 mt-2">
              <h6>Close Collaboration</h6>
              <p>
                We remove the extra layers of dealing with multiple people. Each
                client gets its attention, in-person updates (where not
                possible, we ensure a seamless remote experience), feedback, and
                personal input.
              </p>
            </div>
            <div className="col-xs-12 col-md-6 pr-2 mt-2">
              <h6>Trust &amp; Transperancy</h6>
              <p>
                Transparent about project estimations, processes, and company
                insights - leads to more efficient workflow and flexibility to
                adapt to our client&apos;s needs, budget and time-frames.
              </p>
            </div>
            <div className="col-xs-12 col-md-6 pr-2 mt-2">
              <h6>Quality Over Quantity</h6>
              <p>
                We are in the business of making influential brands. We
                don&apos;t just work to produce. We carefully craft every piece
                of your identity and design to ensure quality and meaning.
              </p>
            </div>
            <div className="col-xs-12 col-md-6 pr-2 mt-2">
              <h6>Curiousity</h6>
              <p>
                We ask a lot of questions. We listen. We are eager to discover
                as much as possible about your challenges, motivations, and
                goals.
              </p>
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5 justify-space-between">
          <StaticImage
            className="col-md-3"
            src="../images/about-branding-colours-identity-design-balazs-ketyi.jpg"
            alt="A computer screen displaying brand identity elements such as colours, text styles, templates, a component library, and more."
            transformOptions={transformOptions}
          />
          <StaticImage
            className="col-md-6"
            src="../images/about-open-book-with-drawings-med-badr-chemmaoui.jpg"
            alt="An open book with rough drawings of a user interface/user experience design."
            transformOptions={transformOptions}
          />
        </div>
        <Divider />
        <div className="mt-5 mb-5 pt-5 pb-5">
          <h4>The Process</h4>
          <div className="row w-100">
            <div className="col-xs-12 col-md-4 pr-2 mt-4">
              <h6>01 Discovery</h6>
              <ul>
                <li> Business Overview</li>
                <li> Competitive Analysis</li>
                <li> Understanding your Customers</li>
                <li> Defining Brand Personality</li>
                <li> Brand Core Values</li>
                <li> Industry Standards</li>
                <li> Priority Goals</li>
              </ul>
            </div>
            <div className="col-xs-12 col-md-4 pr-2 mt-4">
              <h6>02 Design</h6>
              <ul>
                <li> Moodboards &amp; Stylescapes</li>
                <li> Creative Direction</li>
                <li> Brand Foundation</li>
                <li> Typography, Colours, &amp; Visuals</li>
                <li> Standardization &amp; Guidelines</li>
              </ul>
            </div>
            <div className="col-xs-12 col-md-4 pr-2 mt-4">
              <h6>03 Delivery</h6>
              <ul>
                <li> Design Deliverables</li>
                <li> Digital Implementation</li>
                <li> Print Implementation</li>
                <li> Ongoing Support</li>
              </ul>
            </div>
          </div>
        </div>
        <Divider />
      </section>
    </Layout>
  )
}

export default AboutPage
